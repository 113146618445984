import Swiper, {Navigation,EffectFade,Autoplay} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperBanner = new Swiper(".slider-banner", {
    modules: [Navigation,Autoplay],
    slidesPerView: 1, 
    loop: true,
    effect: 'fade',
    // autoplay: {
    //   delay: 5000,
    // },
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    }, 
  });

  var toggle_sound_btn = document.querySelector(".toggle_sound_btn");
  toggle_sound_btn && toggle_sound_btn.addEventListener("click", function (e) {
    var parentSlideEl = this.closest(".block-banner__video");
    if (parentSlideEl) {
      var videoEl = parentSlideEl.querySelector("video");
      videoEl &&
        (this.classList.contains("active")
          ? (this.classList.remove("active"),
            (videoEl.muted = !videoEl.muted))
          : (this.classList.add("active"),
            (videoEl.muted = !videoEl.muted)));
    }
  });
}
  
